import { useEffect, useState, useMemo } from 'react';
import { useSet } from 'react-use';
import { useCurrentOrganization } from 'src/store/organization';

import { apiCreateContent, apiJourneyBrainImage } from 'src/utils/journeyApi';
import { useEditorStore } from '../../editor-store';
import { Block, ImageBlockContent } from '../../types';
import { getImageMetrics } from '../get-image-metrics';
import { useEditorContext } from 'src/store/editor';

const LOADING_TILES = 4;

type ImageTile = {
  show: boolean;
  url?: string;
};

export const createImageBlockContent = async ({
  blockId,
  url,
  type,
  name,
  organizationId,
  dalleParams,
}: {
  blockId: Block['id'];
  url: string;
  type: string;
  name: string;
  organizationId: string;
  dalleParams?: any;
}) => {
  const contentObject = await apiCreateContent(organizationId, {
    file_url: url,
    file_content_type: type,
    dalle_params: dalleParams,
    name: name,
  });

  if (!contentObject) return;

  useEditorStore.getState().dispatchUserEditorAction(
    {
      type: 'set-block-content-uuid',
      id: blockId,
      contentUUID: contentObject.uuid,
    },
    {
      undoable: false,
    }
  );
};

export const useImageBlockOperations = (block: Block) => {
  const [loadedImageSet, { add, has, reset }] = useSet(new Set<string>([]));
  const [activeTab, setActiveTab] = useState<'ai' | 'upload'>('ai');

  const currentOrganization = useCurrentOrganization((state) => state.currentOrganization);
  const dispatchUserEditorAction = useEditorStore((state) => state.dispatchUserEditorAction);
  const clearSelectedBlock = useEditorStore((state) => state.clearSelectedBlock);

  const imageBlockContent = block.content as ImageBlockContent;
  const { dalleParams } = imageBlockContent;

  const onNewImageFile = async (file: File) => {
    if (!(block.content.type === 'placeholder' && block.content.clipboardData)) {
      clearSelectedBlock();
    }
    const url = URL.createObjectURL(file);
    await onNewImageUrl({ url, file });
    URL.revokeObjectURL(url);
  };

  const onNewImageUrl = async ({
    url,
    file,
    dalleParams,
    name,
    contentUUID,
  }: {
    url: string;
    file?: File;
    dalleParams?: any;
    name?: string;
    contentUUID?: string;
  }) => {
    const { width, height, color } = await getImageMetrics(url);
    dispatchUserEditorAction(
      {
        type: 'replace-block',
        id: block.id,
        content: {
          type: 'image',
          url: url,
          file,
          objectFit: 'contain',
          dominantColor: color,
          metadata: name && { name },
          contentUUID,
          ...(dalleParams && { dalleParams }),
        },
        layoutExtraParams: {
          aspectRatio: {
            width,
            height,
          },
        },
      },
      {
        syncable: false,
        undoable: true,
      }
    );
  };

  const updateImageBlockContent = (content: Partial<ImageBlockContent>) => {
    dispatchUserEditorAction(
      {
        type: 'update-block-content',
        id: block.id,
        content,
      },
      {
        syncable: true,
        undoable: true,
      }
    );
  };

  const searchDalle = async (block: Block, prompt: string, service: string) => {
    try {
      reset();
      updateImageBlockContent({
        dalleStatus: 'dalle-generating',
      });
      clearSelectedBlock();

      let promises = [];
      const imageStylePreset = useEditorContext.getState().journey.data.aiImageStylePreset;

      for (let i = 0; i < 4; i++) {
        promises.push(
          apiJourneyBrainImage('generate_from_prompt', {
            prompt,
            style_preset: imageStylePreset,
            count: 1,
            service: service,
          })
        );
      }

      Promise.all(promises).then(async (responses) => {
        const images = responses.map((response) => response.images[0]);

        const mainUrl = images[0].url;
        const dalleParams = {
          urls: images.map((image: any) => image.url),
          prompt: prompt,
        };

        await onNewImageUrl({
          url: mainUrl,
          dalleParams: dalleParams,
        });

        createImageBlockContent({
          blockId: block.id,
          url: mainUrl,
          type: 'image/png',
          name: prompt,
          organizationId: currentOrganization.id,
          dalleParams: dalleParams,
        });

        updateImageBlockContent({
          dalleStatus: 'ready',
        });
      });
    } catch (e) {
      updateImageBlockContent({
        dalleStatus: 'dalle-failed',
      });
      console.error(e);
    }
  };

  const imageTiles: ImageTile[] = useMemo(() => {
    if (imageBlockContent?.dalleStatus === 'none') return [];
    if (imageBlockContent?.dalleStatus === 'dalle-generating') {
      return Array.from({ length: LOADING_TILES }, (_, i) => ({ show: false }));
    }

    const { urls } = dalleParams || {};
    return (urls || []).map((url) => ({
      show: !!url,
      url,
    }));
  }, [dalleParams, imageBlockContent]);

  return {
    empty: !imageBlockContent.dalleStatus || imageBlockContent.dalleStatus === 'none',
    loading: imageBlockContent.dalleStatus === 'dalle-generating',
    imageTiles,
    searchDalle,
    onNewImageUrl,
    onNewImageFile,
    activeTab,
    setActiveTab,
    imageListeners: {
      onLoad: (url: string) => {
        add(url);
      },
    },
  };
};
