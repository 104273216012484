import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Button } from 'src/common/button';
import { JourneyFormik, JourneyForm } from 'src/common/JourneyFormik';
import { FormikTextInput } from 'src/common/form/formik/text-input';
import { NDA_TEMPLATE_URL } from 'src/utils/copy';
import FontFaceObserver from 'fontfaceobserver';
import { FormikProps } from 'formik';

interface EmbedPlayerNdaGatingProps {
  email: string;
  onSignNdaClick: (email: string, signature: string) => void;
}

export const EmbedPlayerNdaGating: React.FC<EmbedPlayerNdaGatingProps> = ({ email, onSignNdaClick }) => {
  const [fontLoaded, setFontLoaded] = useState(false);

  useEffect(() => {
    new FontFaceObserver('Caveat', { weight: 400 })
      .load()
      .then(() => {
        setFontLoaded(true);
      })
      .catch(() => {
        setFontLoaded(true);
      });
  }, []);
  const formikParams = {
    initialValues: { signature: '' },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      signature: Yup.string().required('Please type your full name to sign'),
    }),
    onSubmit: (values: Record<string, string>) => {
      onSignNdaClick(email || '', values.signature);
    },
  };

  return (
    <div className='flex flex-col gap-8'>
      <div className='flex flex-col text-bedrock-black space-y-2 md:space-y-4'>
        <h1 className='text-bedrock-serif-h2 md:text-bedrock-serif-h1'>Non-disclosure agreement</h1>
        <div className='text-bedrock-p max-w-none md:max-w-[37.5rem]'>
          This Journey requires a non-disclosure agreement. Type your full name to sign and proceed. A signed copy will
          be emailed to you. By signing you agree to use electronic records and signatures and to the Journey{' '}
          <a href='https://www.journey.io/terms' className='underline' target='_blank' rel='noopener noreferrer'>
            Terms of Service.
          </a>
        </div>
      </div>
      <JourneyFormik
        {...formikParams}
        render={(formProps: FormikProps<Record<string, string>>) => (
          <JourneyForm>
            <FormikTextInput
              id='nda-signature'
              name='signature'
              placeholder='Sign here'
              required={true}
              autoFocus={true}
              className='w-full'
              showBorder={false}
              style={{
                fontFamily: "'Caveat', cursive",
                lineHeight: '56px',
                fontWeight: 400,
                fontSize: 30,
                opacity: fontLoaded ? 1 : 0,
              }}
            />
            <div className='flex self-start space-x-2 mt-8 max-w-none md:max-w-xs'>
              <Button
                className='w-1/2 shrink-0'
                variant='primary'
                label='Sign and proceed'
                size='regular'
                type='submit'
                disabled={formProps.values.signature === ''}
              />
              <a className='w-1/2 shrink-0' href={NDA_TEMPLATE_URL} target='_blank' rel='noopener noreferrer'>
                <Button className='w-full' variant='secondary' label='View document' size='regular' />
              </a>
            </div>
          </JourneyForm>
        )}
      />
    </div>
  );
};
