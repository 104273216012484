import React, { useEffect, useState } from 'react';
import { VideoAsset } from 'src/common/helpers/video_asset';
import { AliasCustomFieldType, AliasCustomFieldValueType } from 'src/common/interfaces/journey/alias.interface';
import { useNotificationMessage } from 'src/common/notification/message.hook';
import { JourneyEmailGating } from 'src/player/JourneyEmailGating';
import { Nullable } from 'src/types/nullable.type';
import { apiUnlockJourney, apiCreateEmailVerification, apiVerifyEmail } from 'src/utils/journeyApi';
import { SegmentEventName, trackSegmentEvent } from 'src/utils/segment';

export const EmbedPlayerEmailGating = ({
  journey,
  userEmail,
  uuid,
  emailRequired,
  passwordRequired,
  emailVerificationRequired,
  ndaSignatureRequired,
  customFields,
  introVideoAsset,
  trackingUUID,
  sessionUUID,
  onNdaSigned,
  onEmailSubmitted,
  onCustomFieldValuesSubmitted,
  onJourneyUnlocked,
}: {
  journey: any;
  uuid: string;
  userEmail: Nullable<string>;
  emailRequired: boolean;
  emailVerificationRequired: boolean;
  ndaSignatureRequired: boolean;
  trackingUUID: string;
  sessionUUID: string;
  passwordRequired: boolean;
  customFields: AliasCustomFieldType[];
  introVideoAsset?: VideoAsset | null;
  onNdaSigned: (email: string, signature: string) => void;
  onEmailSubmitted: (email: string) => void;
  onCustomFieldValuesSubmitted?: (customFieldValues: AliasCustomFieldValueType[]) => void;
  onJourneyUnlocked: (email?: string) => Promise<any>;
}) => {
  const [accessCodeRequired, setAccessCodeRequired] = useState(false);
  const { setSuccessNotification } = useNotificationMessage();

  const logToAnalytics = (eventName: SegmentEventName, email: string = '') => {
    trackSegmentEvent(eventName, {
      email: email,
      journey_alias_uuid: journey.alias.uuid,
    });
  };

  useEffect(() => {
    if (emailRequired) {
      logToAnalytics('Email Gate Opened', '');
    }
    // @anilsevim
    // for some reason when i include logToAnalytics, it constantly triggers this so we don't want that.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailRequired]);

  const unlockJourney = (email: string, password: string = '', custom_fields = []) =>
    apiUnlockJourney(uuid, {
      email,
      password,
      tracking_uuid: trackingUUID,
      session_uuid: sessionUUID,
      custom_fields,
    });

  const onUnlockSubmitted = (
    {
      email,
      password,
      access_code,
      ...formCustomFields
    }: {
      email: string;
      password: string;
      access_code: string | null;
    },
    resent?: boolean
  ) => {
    onEmailSubmitted(email);
    submitCustomFieldsIfAvailable(formCustomFields);
    if (accessCodeRequired && !resent) {
      return apiVerifyEmail(journey.alias.uuid, access_code).then(() => {
        return onJourneyUnlocked(email);
      });
    } else if (emailVerificationRequired || resent) {
      return unlockJourney(email, password).then(() => {
        return apiCreateEmailVerification(journey.alias.uuid, email).then(() => {
          setAccessCodeRequired(true);
          if (!resent) {
            setSuccessNotification('An access code is sent to your email. Please enter that code below.');
          } else {
            setSuccessNotification('An access code is resent to your email.');
          }
          logToAnalytics('Email Gate Access Code Sent', email);
        });
      });
    } else if (emailRequired || passwordRequired) {
      return unlockJourney(email, password).then(() => {
        return onJourneyUnlocked(email);
      });
    }

    return onJourneyUnlocked();
  };

  const onCodeExpired = () => {
    setAccessCodeRequired(false);
  };

  const submitCustomFieldsIfAvailable = (formCustomFields: any = {}) => {
    let customFieldValues: AliasCustomFieldValueType[] = [];

    if (formCustomFields) {
      // loop through formCustomFields
      Object.keys(formCustomFields).forEach((key) => {
        // find the custom field in customFields
        const customField = customFields.find((customField) => customField.field === key);

        if (customField) {
          // if found, add it to customFieldValues
          customFieldValues.push({
            ...customField,
            value: formCustomFields[key],
          });
        }
      });
    }

    if (customFieldValues && customFieldValues.length > 0) {
      onCustomFieldValuesSubmitted && onCustomFieldValuesSubmitted(customFieldValues);
    }
  };

  const showEmailGate = emailRequired || emailVerificationRequired || passwordRequired || ndaSignatureRequired;

  if (!showEmailGate) return null;

  return (
    <JourneyEmailGating
      journey={journey}
      email={userEmail}
      emailRequired={emailRequired}
      passwordRequired={passwordRequired}
      accessCodeRequired={accessCodeRequired}
      emailVerificationRequired={emailVerificationRequired}
      ndaSignatureRequired={ndaSignatureRequired}
      customFields={customFields}
      introVideoAsset={introVideoAsset}
      onSubmit={onUnlockSubmitted}
      onCodeExpired={onCodeExpired}
      onBypassClick={onJourneyUnlocked}
      onResendClick={(email) => {
        onUnlockSubmitted({ email, password: '', access_code: null }, true);
      }}
      onNdaSigned={onNdaSigned}
    />
  );
};
