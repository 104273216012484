import omit from 'lodash/omit';

import { SentryHelper } from 'src/common/sentry';

const identifyUser = (user: any) => {
  // Segment identify
  window.analytics &&
    window.analytics.identify(user.uuid, {
      name: user.name,
      email: user.email,
      last_organization: omit(user.last_organization),
      created_at: user.created_at && user.created_at,
    });

  SentryHelper.setUser(user.email);
};

const trackPage = () => {
  // Segment page
  window.analytics && window.analytics.page && window.analytics.page();
};

const AnalyticsHelper = {
  identifyUser,
  trackPage,
};

export default AnalyticsHelper;
