import { animated, SpringValue, SpringValues } from '@react-spring/web';
import classNames from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { BlockRenderElement, Rect } from '../types';
import { SectionThumbnail } from '../components/section-thumbnail';
import { SectionThumbnailNew } from '../components/section-thumbnail-new';

const THUMBNAIL_LAYOUT = {
  width: 56,
  height: 42,
  contentPadding: 2,
};

type PlayerSectionCardProps = {
  id: string;
  index: number;
  title: string;
  current: boolean;
  blockRenderElements: BlockRenderElement[];
  showIndices: boolean;
  expandedFraction: SpringValue<number>;
  sectionDrawerOpen: boolean;
  style: SpringValues<{ y: number }>;
  rect: Rect;
  onCardClick: () => void;
  order?: number;
};

export const NeuePlayerSectionCardNew = React.forwardRef<HTMLDivElement, PlayerSectionCardProps>(
  (
    {
      id,
      index,
      title,
      current,
      blockRenderElements,
      showIndices,
      style,
      rect,
      expandedFraction,
      sectionDrawerOpen,
      onCardClick,
      order,
    }: PlayerSectionCardProps,
    ref
  ) => {
    return (
      <animated.div
        className={classNames(
          'group/player-section-bar pl-6 pb-2 flex gap-1 items-center transition-opacity cursor-pointer'
        )}
        onClick={onCardClick}
        ref={ref}
      >
        <div className='flex flex-1 gap-2 items-center'>
          <div
            className={classNames('handle transition-opacity relative', {
              'opacity-100': current,
              'opacity-70 group-hover/player-section-bar:opacity-100': !current,
            })}
          >
            {typeof order !== 'undefined' && (
              <span className='flex w-full h-full justify-center font-semibold text-lg text-neue-canvas-fg items-center absolute z-[9999]'>
                {order + 1}
              </span>
            )}
            <SectionThumbnailNew
              thumbnailLayout={THUMBNAIL_LAYOUT}
              blockRenderElements={blockRenderElements}
              layoutMode='web'
              className={classNames({ 'cursor-pointer': true })}
              current={current}
            />
          </div>
          <animated.div
            className={classNames('flex flex-1 relative', {
              'pointer-events-none': !sectionDrawerOpen,
            })}
            style={{
              opacity: expandedFraction.to((fraction) => fraction),
              transform: expandedFraction.to((fraction) => `translateX(-${(1 - fraction) * 8}px)`),
            }}
          >
            <div
              className={classNames(
                'flex-1 w-full max-w-[160px] text-neue-journey-medium-strong line-clamp-2 transition bg-transparent min-w-0',
                'cursor-pointer text-neue-canvas-fg',
                {
                  'opacity-100': current,
                  'opacity-70 group-hover/player-section-bar:opacity-100': !current,
                }
              )}
            >
              <p className='text-nowrap truncate'>{title || 'Untitled'}</p>
            </div>
          </animated.div>
        </div>
      </animated.div>
    );
  }
);
