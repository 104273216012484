import React from 'react';
import { Content } from 'src/common/interfaces/content.interface';
import { useCurrentOrganization } from 'src/store/organization';
import { apiCreateContent, apiGetEmbedDetailsFromUrl, apiUploadContent } from 'src/utils/journeyApi';
import { runWithRetries, useEditorStore } from '../../editor-store';
import { EmbedCheckResponse, LinkDisplayOption, LinkResponse } from '../../links/types';
import {
  AspectRatio,
  AttachmentBlockContent,
  Block,
  BlockContent,
  LinkBlockContent,
  LogoBlockContent,
  BlockLayoutExtraParams,
  PdfMetadata,
  MsOfficeBlockContent,
  NativeESignatureBlockContent,
} from '../../types';
import { getFileMetadata } from 'src/utils/pdf';
import { getAttachmentSubtitleFromBlockContent } from '../block-types/attachment';
import { FileAsset } from 'src/common/interfaces/file-asset.interface';
import { ImageAsset } from 'src/common/interfaces/image-asset.interface';
import { urlContentTypeToBlockType } from '../url-content-type-to-block-type';
import { fetchUrlAsBlob } from 'src/utils/file';
import { useGetActiveLogoStack } from '../../logo/get-active-logo-stack.hook';
import { uploadFileToS3 } from 'src/common/helpers/file-upload';
import { useLogoStore } from '../../logo/store';
import { useBlockIdActions } from './use-block-id-actions.hook';
import { getInitialMsOfficeBlockContent, getInitialPdfBlockContent } from '../get-initial-block.content';
import { useImageBlockIdOperations } from '../block/use-image-block-id-operations';
import { formatUrl } from 'src/utils/url';

export const useBlockActions = (block: Block) => {
  const dispatchUserEditorAction = useEditorStore((state) => state.dispatchUserEditorAction);
  const currentOrganization = useCurrentOrganization((state) => state.currentOrganization);

  const logoBlock = useGetActiveLogoStack(block);
  const updateLogoBlock = useLogoStore((state) => state.updateLogoBlock);

  const { onNewImageFileByBlockId } = useImageBlockIdOperations();
  const { onNewFileByBlockId } = useBlockIdActions();

  const onLogoBlockUpdated = (payload: Partial<LogoBlockContent>) => {
    // console.info(logoBlock, ' < logoBlock');
    updateLogoBlock(block.id, payload);
    dispatchUserEditorAction(
      {
        type: 'update-block-content',
        id: block.id,
        content: {
          type: 'logo',
          selectedOrgLogo: logoBlock.selectedOrgLogo,
          selectedRecipientLogo: logoBlock.selectedRecipientLogo,
          ...payload,
        },
      },
      {
        syncable: true,
        undoable: false,
      }
    );
  };

  const onNewLink = (linkResponse: LinkResponse, displayOption: LinkDisplayOption) => {
    const content = {
      type: 'link',
      link: linkResponse,
      displayOption,
    } as LinkBlockContent;

    dispatchUserEditorAction(
      {
        type: 'replace-block',
        id: block.id,
        content: content,
        layoutExtraParams: {
          aspectRatio: {
            width: 1.77,
            height: 1,
          },
        },
      },
      {
        syncable: true,
        undoable: true,
      }
    );

    if (linkResponse.url.includes('loom.com/share/')) {
      return;
    }

    if (!(block.content as LinkBlockContent).personalContent) {
      apiCreateContent(currentOrganization.id, {
        link_url: linkResponse.url,
      })
        .then((response: Content) => {
          dispatchUserEditorAction(
            {
              type: 'set-block-content-uuid',
              id: block.id,
              contentUUID: response.uuid,
            },
            {
              syncable: true,
              undoable: false,
            }
          );
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const onDeleteBlock = () => {
    dispatchUserEditorAction({
      type: 'delete-block',
      id: block.id,
    });
  };

  const onBlockParamsChange = (
    blockId: string,
    params: any = {},
    config: { undoable: boolean; syncable: boolean } = { undoable: true, syncable: true }
  ) => {
    dispatchUserEditorAction(
      {
        type: 'update-block-content',
        id: block.id,
        content: params,
      },
      config
    );

    if (!params.personalContent && params.contentObject) {
      apiCreateContent(currentOrganization.id, params.contentObject).then((response: Content) => {
        dispatchUserEditorAction(
          {
            type: 'set-block-content-uuid',
            id: blockId,
            contentUUID: response.uuid,
          },
          {
            syncable: true,
            undoable: false,
          }
        );
      });
    }
  };

  const onNewVideoContentFromLibrary = (content: Content) => {
    const { video_asset } = content;
    if (!video_asset) {
      return;
    }

    const layoutExtraParams: BlockLayoutExtraParams = {};
    if (video_asset.mux_asset.aspect_ratio) {
      const [width, height] = video_asset.mux_asset.aspect_ratio.split(':');
      layoutExtraParams.aspectRatio = {
        width: parseInt(width, 10),
        height: parseInt(height, 10),
      };
    }

    dispatchUserEditorAction(
      {
        type: 'replace-block',
        content: {
          ...content,
          type: 'video',
          contentUUID: content.uuid,
          muxPlaybackId: video_asset.mux_asset.public_playback_id,
          metadata: {
            aspectRatio: layoutExtraParams.aspectRatio || undefined,
            name: video_asset.name,
            size: 0,
            duration: video_asset.duration || video_asset.mux_asset.duration || 0,
          },
          hasTranscript: video_asset.has_transcript,
        },
        id: block.id,
        layoutExtraParams,
      },
      { syncable: true, undoable: true }
    );
  };

  const onNewVideoFromExternalUrl = (
    url: string,
    metadata: any,
    importParams?: {
      source: string;
      url: string;
      data?: any;
    }
  ) => {
    const { name, duration } = metadata;
    const layoutExtraParams: BlockLayoutExtraParams = {};
    let aspectRatio: AspectRatio = { width: 16, height: 9 };
    layoutExtraParams.aspectRatio = aspectRatio;

    dispatchUserEditorAction(
      {
        type: 'replace-block',
        content: {
          type: 'video',
          externalUrl: url,
          metadata: {
            aspectRatio,
            name: name,
            size: 0,
            duration: duration,
          },
          importParams,
        },
        id: block.id,
        layoutExtraParams,
      },
      { syncable: true, undoable: true }
    );
  };

  const onNewPdfContentFromLibrary = async (content: Content, onSuccess: () => void) => {
    const { file_asset } = content;

    if (!file_asset) {
      return;
    }

    const pdfHasThumbnail = content.thumbnail ? !!content.thumbnail.url : false;
    const pdfMetadata = await getFileMetadata(content.file_asset!.url, pdfHasThumbnail);

    const metadata: PdfMetadata = {
      name: file_asset.name,
      width: pdfMetadata?.width || file_asset.metadata?.width || 0,
      height: pdfMetadata?.height || file_asset.metadata?.height || 0,
      numPages: pdfMetadata?.num_pages || file_asset.metadata?.num_pages || 0,
      size: 0,
    };

    const layoutExtraParams: BlockLayoutExtraParams = {};

    if (file_asset.metadata?.aspect_ratio) {
      const [width, height] = file_asset.metadata.aspect_ratio.split(':');
      layoutExtraParams.aspectRatio = {
        width: parseInt(width, 10),
        height: parseInt(height, 10),
      };
    }

    dispatchUserEditorAction(
      {
        type: 'replace-block',
        layoutExtraParams,
        id: block.id,
        content: {
          metadata,
          type: 'pdf',
          jnyContent: content,
          contentUUID: content.uuid,
          allowDownload: true,
          thumbnailUrl: content.thumbnail?.url || null,
        },
      },
      { syncable: true, undoable: true }
    );
    onSuccess();
  };

  const onNewAttachmentContentFromLibrary = (
    asset: FileAsset | ImageAsset,
    contentUuid: string,
    onSuccess?: () => void
  ) => {
    if (!asset) {
      return;
    }

    const attachmentBlockContent: AttachmentBlockContent = {
      type: 'attachment',
      contentType: asset.content_type,
      contentUUID: contentUuid,
      url: asset.url,
      title: asset.name,
      subtitle: '',
    };

    attachmentBlockContent.subtitle = getAttachmentSubtitleFromBlockContent(attachmentBlockContent);

    dispatchUserEditorAction(
      {
        type: 'replace-block',
        id: block.id,
        content: attachmentBlockContent,
        layoutExtraParams: {},
      },
      {
        syncable: true,
        undoable: true,
      }
    );
    if (onSuccess) {
      onSuccess();
    }
  };

  const onNewMsOfficeContentFromLibrary = (asset: FileAsset, contentUUID: string, onSuccess?: () => void) => {
    if (!asset) {
      return;
    }

    const msOfficeBlockContent: MsOfficeBlockContent = {
      type: 'ms-office',
      contentType: asset.content_type,
      url: asset.url,
      name: asset.name,
      contentUUID: contentUUID,
    };

    dispatchUserEditorAction(
      {
        type: 'replace-block',
        id: block.id,
        content: msOfficeBlockContent,
        layoutExtraParams: {},
      },
      {
        syncable: true,
        undoable: true,
      }
    );
    if (onSuccess) {
      onSuccess();
    }
  };

  const onNewUrl = async (
    url: string,
    title = '',
    checkResponse?: EmbedCheckResponse,
    isCTA?: boolean,
    isLinkBox?: boolean
  ) => {
    let searchParams = '';
    const formattedURL = formatUrl(url);
    try {
      const urlObject = new URL(formattedURL);
      searchParams = urlObject.search;
    } catch (error) {
      console.error('Error parsing URL:', error);
    }

    const response = checkResponse || (await apiGetEmbedDetailsFromUrl(url));
    const { content_type } = response;

    const blockContentType = urlContentTypeToBlockType(url, content_type, isCTA);

    if (blockContentType === 'link') {
      const linkResponse: LinkResponse = {
        url: response.url,
        transformed_url: response.transformed_url.includes(searchParams)
          ? response.transformed_url
          : response.transformed_url + searchParams,
        title: title || response.oembed.title,
        thumbnail: {
          url: response.oembed?.thumbnail_url || null,
          width: response.oembed?.thumbnail_width || null,
          height: response.oembed?.thumbnail_height || null,
        },
        destination: response.destination,
        iframe_safe: response.iframe_safe,
        third_party_iframe_safe: response.third_party_iframe_safe,
      };

      let displayOption: LinkDisplayOption = 'new-tab';
      if (response.iframe_safe && !isLinkBox) {
        displayOption = 'inline';
      }

      onNewLink(linkResponse, displayOption);
    } else if (blockContentType === 'cta-button') {
      dispatchUserEditorAction(
        {
          type: 'update-block-content',
          id: block.id,
          content: {
            type: 'cta-button',
            target: url,
          },
        },
        {
          syncable: true,
          undoable: false,
        }
      );
    } else if (blockContentType === 'video') {
      onNewVideoFromExternalUrl(response.url, response.meta_data);
    } else if (blockContentType === 'image' || blockContentType === 'pdf' || blockContentType === 'ms-office') {
      // await createContentFromExternalFileUrl(response.url);
      // onNewImageUrl({ url });

      // onNewPdfContentFromLibrary(content);
      // we need to upload the file so we don't run into CORS issue here
      // const content = await createContentFromExternalFileUrl(response.url);

      const { url, name, content_type } = await apiUploadContent(response.url);

      const fileBlob = await fetchUrlAsBlob(url);
      const file = new File([fileBlob], name, { type: content_type });

      if (blockContentType === 'image') {
        onNewImageFileByBlockId(block.id)(file);
        return;
      } else {
        let initialContent: BlockContent | undefined;
        let layoutExtraParams: BlockLayoutExtraParams = {};
        if (blockContentType === 'pdf') {
          initialContent = await getInitialPdfBlockContent(file);
          if (initialContent) {
            const { metadata } = initialContent;
            const aspectRatio = metadata && metadata.width && metadata.height ? metadata : undefined;
            layoutExtraParams = { aspectRatio };
          }
        } else if (blockContentType === 'ms-office') {
          initialContent = await getInitialMsOfficeBlockContent(file);
        }

        if (!initialContent) {
          onBlockParamsChange(block.id, { fileStatus: 'error' });
          return;
        }
        dispatchUserEditorAction(
          {
            type: 'replace-block',
            id: block.id,
            content: initialContent,
            layoutExtraParams,
          },
          {
            syncable: true,
            undoable: true,
          }
        );
      }
    }
  };

  const onNewFile = onNewFileByBlockId(block.id);

  const onUploadNewFile = (file: File, folder = 'logos') => {
    return runWithRetries(() => uploadFileToS3(file, folder));
  };

  const onNewNativeESignatureBlock = (content: Partial<NativeESignatureBlockContent>) => {
    dispatchUserEditorAction(
      {
        type: 'replace-block',
        id: block.id,
        content: {
          type: 'native-e-signature',
          highlighted: true,
          pinned: true,
          signees: content.signees || [],
        },
        layoutExtraParams: {},
      },
      {
        syncable: true,
        undoable: true,
      }
    );
  };

  return {
    onLogoBlockUpdated,
    onNewLink,
    onDeleteBlock,
    onBlockParamsChange,
    onNewUrl,
    onUploadNewFile,
    onNewFile,
    onNewPdfContentFromLibrary,
    onNewAttachmentContentFromLibrary,
    onNewMsOfficeContentFromLibrary,
    onNewVideoContentFromLibrary,
    onNewVideoFromExternalUrl,
    onNewNativeESignatureBlock,
  };
};
