import { Nullable } from 'src/types/nullable.type';
import { create } from 'zustand';
import { generateBlockId } from './helpers/generate-block-id';
import {
  ActionSource,
  Block,
  BlockContent,
  Boundary,
  ThemeSettings,
  ContextMenuState,
  MutualActionPlanBlockContent,
  Operation,
  PdfBlockContent,
  PdfMetadata,
  RenderElement,
  Section,
  SectionGrid,
  Size,
  TextBlockContent,
  SetJourneyColorSettingsAction,
  UserEditorAction,
  VideoBlockContent,
  SetJourneyNameAction,
  RootRenderMode,
  SectionLayoutInfo,
  ResizeDragState,
  HasContentUUID,
  LayoutStage,
  BlockRenderElement,
} from './types';
import produce from 'immer';
import { DEFAULT_LAYOUT_CONFIG, LayoutManager } from './layout-manager';
import { sync } from './editor-sync';
import { updateLayoutManagerForAction } from './helpers/actions/update-layout-manager-for-action';
import { uploadFileToS3 } from 'src/common/helpers/file-upload';
import { isMutualActonPlanAction, MutualActionPlanAction } from './mutual-action-plans/types';
import { useMutualActionPlansContext } from './mutual-action-plans/store';
import { inverseOperations } from './helpers/operations/inverse-operations';
import { useCurrentOrganization } from 'src/store/organization';
import { findBlockById } from './helpers/block/find-block-by-id';
import {
  arePdfBlockResourcesReady,
  extractPdfBlockContentFromFile,
  fetchPdfBlockJnyContent,
  uploadPdfBlockFile,
} from './helpers/block-types/pdf';
import { extractAttachmentBlockContentFromFile, uploadAttachmentBlockFile } from './helpers/block-types/attachment';
import { fetchVideoBlockPlaybackParams, uploadVideoBlockFile, uploadVideoUrl } from './helpers/block-types/video';
import { createImageBlockContent } from './helpers/block/use-image-block-operations';
import { maybeSetSectionTitle, maybeSetSectionTitleOnMutualActionPlanTitleUpdate } from './helpers/section/autonaming';
import { DEFAULT_FONT_VALUES, DEFAULT_THEME_COLOR_VALUES } from './helpers/themes/get-theme-properties';
import throttle from 'lodash/throttle';
import { LayoutConfig } from './layout-manager/types';
import {
  addOngoingUploadAborter,
  getOngoingUploadAborter,
  removeOngoingUploadAborter,
} from './helpers/ongoing-uploads-aborter-mapping';
import fastq, { queueAsPromised } from 'fastq';
import { subscribeWithSelector } from 'zustand/middleware';
import { findBlock } from './helpers/find-block';
import { getFeatureFlag } from './feature-flags-store';
import debounce from 'lodash/debounce';
import { hasAnyBlockWithoutHeight } from './helpers/has-any-block-without-height';
import { getFileMetadata } from 'src/utils/pdf';
import { Awaited } from 'src/types/awaited.type';

export type DragPosition = {
  x: number;
  y: number;
};

export type MousePosition = {
  clientX: number;
  clientY: number;
};

export type DraggableState = {
  position: DragPosition;
  isDragging: boolean;
};

export type Coords = {
  x: number;
  y: number;
};

export type HiddenState = {
  id: string;
  hidden: boolean;
};

export const EditorContentNamePdfMetaMapping = new Map<string, Awaited<ReturnType<typeof getFileMetadata>>>();

type StateVars = {
  initialized: boolean;
  postInitInvoked: boolean;
  layoutStage: LayoutStage;
  layout: {
    sections: Section[];
    sectionGrids: SectionGrid[];
    sectionLayoutInfos: SectionLayoutInfo[];
    renderElements: RenderElement[];
    boundaries: Boundary[];
    layoutReady: boolean;
    innerActualWidth: Nullable<number>;
    innerActualHeight: Nullable<number>;
    layoutConfig: LayoutConfig;
  };
  rootRenderMode: RootRenderMode;
  journeyUUID: Nullable<string>;
  journeyName: Nullable<string>;
  lastActionWasUndoOrRedo: boolean;
  noUndoableActionYet: boolean;
  latestTrackableSections: Nullable<Section[]>;
  undoStack: UserEditorAction[];
  redoStack: UserEditorAction[];
  resizeDragState: Nullable<ResizeDragState>;
  hoverBlockId: Nullable<Block['id']>;
  focusedBlockId: Nullable<Block['id']>;
  selectedBlockId: Nullable<Block['id']>;
  currentSectionId: Nullable<Section['id']>;
  scrollToSectionId: Nullable<Section['id']>;
  initialSectionId: Nullable<Section['id']>;
  selectionCoords: Nullable<Coords>;
  editorContentElement: Nullable<HTMLElement>;
  scrollToBlockId: Nullable<Block['id']>;
  contextMenuState: Nullable<ContextMenuState>;
  // _draggableId: Nullable<string>;
  // position: DragPosition;
  isDraggingSomethingOnEditor: boolean;
  isDraggingNewBlock: boolean;
  reorderBoundary: Nullable<Boundary>;
  insertionBoundary: Nullable<Boundary>;
  appendingSection: boolean;
  editorContentRect: Nullable<DOMRect>;
  outerAreaRect: Nullable<DOMRect>;
  innerAreaRect: Nullable<DOMRect>;
  canvasScrollPosition: { x: number; y: number };
  online: boolean;
  initialTitleBlockId: Nullable<Block['id']>;
  themeSettings: ThemeSettings;
  sectionDrawerOpen: boolean;
  featureFlags: {};
  sectionsHidden: HiddenState[];
};

type StateFns = {
  undo: () => void;
  redo: () => void;
  setResizeDragState: (state: Nullable<ResizeDragState>) => void;
  setHoverBlockId: (blockId: Nullable<Block['id']>) => void;
  setFocusedBlockId: (blockId: Nullable<Block['id']>) => void;
  selectBlock: (blockId: Block['id'], coords?: Coords) => void;
  clearSelectedBlock: () => void;
  setCurrentSectionId: (sectionId: Section['id']) => void;
  setScrollToSectionId: (sectionId: Nullable<Section['id']>) => void;
  clearScrollToInitialSection(): void;
  setScrollToBlockId: (blockId: Block['id']) => void;
  setCanvasScrollPosition: (x: number, y: number) => void;
  setContainerRects: (
    rects: { editorContentRect: DOMRect; outerAreaRect: DOMRect; innerAreaRect: DOMRect },
    editorContentElement: HTMLElement
  ) => void;
  setBlockContentSize: (blockId: Block['id'], size: Nullable<Size>) => void;
  setContextMenuState: (state: ContextMenuState) => void;
  clearContextMenuState: () => void;
  dispatchUserEditorAction: (
    userEditorAction: UserEditorAction,
    config?: { source?: ActionSource; undoable?: boolean; syncable?: boolean }
  ) => Promise<any>;
  initEditor: (
    journeyUUID: string,
    journeyName: string,
    sections: Section[],
    themeSettings: ThemeSettings,
    featureFlags: {},
    initialSectionId: Nullable<Section['id']>
  ) => Promise<void>;
  clearLastActionWasUndoOrRedo: () => void;
  resetEditor(): void;
  getSelectedBlock: () => Nullable<Block>;
  getBlockById: (blockId: Block['id']) => Nullable<Block>;
  getSectionById: (sectionId: Section['id']) => Nullable<Section>;
  setOffline(): void;
  setOnline(): void;
  setInitialTitleBlockId(id: Nullable<Block['id']>): void;
  setReorderBoundary: (boundary: Nullable<Boundary>) => void;
  setInsertionBoundary: (boundary: Nullable<Boundary>) => void;
  setIsDraggingSomethingOnEditor(isDragging: boolean): void;
  setRootRenderMode(mode: RootRenderMode): void;
  findBlockByContentUuid: (uuid: string) => Nullable<Block>;
  findSectionById: (sectionId: Section['id']) => Nullable<Section>;
  triggerBlockResourceLoad(blockId: string): void;
  setSectionDrawerOpen: (open: boolean | ((open: boolean) => boolean)) => void;
  setSectionHidden: (id: string, hidden: boolean) => void;
};

type State = StateVars & StateFns;

function createInitialState(): StateVars {
  return {
    initialized: false,
    postInitInvoked: false,
    layoutStage: 'initialized',
    layout: {
      sections: [],
      sectionGrids: [],
      renderElements: [],
      sectionLayoutInfos: [],
      boundaries: [],
      layoutReady: false,
      innerActualWidth: null,
      innerActualHeight: null,
      layoutConfig: DEFAULT_LAYOUT_CONFIG,
    },
    rootRenderMode: 'editor',
    journeyUUID: null,
    journeyName: null,
    lastActionWasUndoOrRedo: false,
    noUndoableActionYet: true,
    latestTrackableSections: null,
    undoStack: [],
    redoStack: [],
    resizeDragState: null,
    hoverBlockId: null,
    focusedBlockId: null,
    selectedBlockId: null,
    currentSectionId: null,
    selectionCoords: null,
    scrollToBlockId: null,
    scrollToSectionId: null,
    initialSectionId: null,
    editorContentElement: null,
    contextMenuState: null,
    isDraggingSomethingOnEditor: false,
    isDraggingNewBlock: false,
    reorderBoundary: null,
    insertionBoundary: null,
    appendingSection: false,
    editorContentRect: null,
    outerAreaRect: null,
    innerAreaRect: null,
    canvasScrollPosition: { x: 0, y: 0 },
    online: true,
    initialTitleBlockId: null,
    themeSettings: {
      theme: 'default',
      brandColor: '#ffffff',
      // default theme
      colorValues: DEFAULT_THEME_COLOR_VALUES,
      fontValues: DEFAULT_FONT_VALUES,
    },
    sectionDrawerOpen: true,
    featureFlags: {},
    sectionsHidden: [],
  };
}

const lm = new LayoutManager();

function updateStoreWithLmState(lm: LayoutManager, layoutStage: LayoutStage, invalidator: string) {
  const { outputs } = lm;
  if (!outputs) {
    return;
  }
  // console.log('lm update store', invalidator, 'layoutStage', layoutStage);
  useEditorStore.setState(
    produce(useEditorStore.getState(), (state) => {
      state.layout = {
        renderElements: outputs.renderElements,
        sections: lm.sections,
        sectionGrids: lm.sectionGrids,
        sectionLayoutInfos: outputs.sectionLayoutInfos,
        boundaries: lm.boundaries,
        layoutReady: true,
        innerActualWidth: outputs.innerActualWidth,
        innerActualHeight: outputs.innerActualHeight,
        layoutConfig: lm.layoutConfig,
      };
      state.layoutStage = layoutStage;
    })
  );
  checkAndInvokePostInit();
}
const debouncedUpdateStoreWithLmState = debounce(updateStoreWithLmState, 500);

lm.addListener('update', (invalidator: string) => {
  // console.log('lm update', invalidator);
  const { outputs, sections } = lm;
  if (!outputs) {
    return;
  }
  // console.log('lm update', invalidator, 'has outputs');
  const { layoutStage } = useEditorStore.getState();
  let newLayoutStage = layoutStage;
  // console.log('lm update', invalidator, 'layoutStage', layoutStage);
  if (layoutStage === 'initialized') {
    if (hasAnyBlockWithoutHeight(sections, lm.blockContentSizes)) {
      newLayoutStage = 'waiting-for-sizes';
    } else {
      newLayoutStage = 'ready';
    }
    updateStoreWithLmState(lm, newLayoutStage, invalidator);
  } else if (layoutStage === 'waiting-for-sizes') {
    if (hasAnyBlockWithoutHeight(sections, lm.blockContentSizes)) {
      // console.log('lm update skipped', invalidator);
      return;
    }
    newLayoutStage = 'ready';
    debouncedUpdateStoreWithLmState(lm, newLayoutStage, invalidator);
  } else if (layoutStage === 'ready') {
    debouncedUpdateStoreWithLmState.cancel();
    updateStoreWithLmState(lm, newLayoutStage, invalidator);
  } else {
    const _exhaustiveCheck: never = layoutStage;
  }
});

const FILE_UPLOAD_RETRIES = 3;
const FILE_UPLOAD_RETRY_DELAY = 5000;

export async function runWithRetries<T>(fn: () => Promise<T>, retries = FILE_UPLOAD_RETRIES): Promise<T | undefined> {
  for (let i = 0; i < retries; i++) {
    try {
      return await fn();
    } catch (e) {
      if ((e as any).code === 'RequestAbortedError') {
        return;
      }
      if (i === retries - 1) {
        return;
      }
      await new Promise((resolve) => setTimeout(resolve, FILE_UPLOAD_RETRY_DELAY));
    }
  }
}

function postSetBlockContentOperationUploadHandler(blockId: Block['id'], blockContent: Block['content']) {
  const orgId = useCurrentOrganization.getState().currentOrganization.id;
  const updateBlockContent = (
    blockId: string,
    content: Partial<BlockContent>,
    config: { undoable: boolean; syncable: boolean } = { undoable: false, syncable: false }
  ) => {
    useEditorStore.getState().dispatchUserEditorAction(
      {
        type: 'update-block-content',
        id: blockId,
        content,
      },
      config
    );
  };

  const uploadProgressCallback = throttle((progress: number) => {
    const block = getBlockIfExists(blockId);
    if (block) {
      updateBlockContent(blockId, { fileUploadProgress: progress });
    }
  }, 1000);

  const assignUploadAborter = (aborter: () => void) => {
    if (!getOngoingUploadAborter(blockId)) {
      addOngoingUploadAborter(blockId, aborter);
    }
  };

  const getBlockIfExists = (blockId: string) =>
    useEditorStore
      .getState()
      .layout.sections.flatMap((section) => section.blocks)
      .find((block) => block.id === blockId);

  setTimeout(async () => {
    if (blockContent.type === 'image') {
      let url;
      if (!blockContent.url || blockContent.url.startsWith('blob:')) {
        const file = blockContent.file;
        updateBlockContent(blockId, { fileUploadStatus: 'in-progress' });
        const response = await runWithRetries(() =>
          uploadFileToS3(file, 'neue-test', uploadProgressCallback, assignUploadAborter)
        );

        if (!response) {
          updateBlockContent(blockId, { fileUploadStatus: 'error' });
          return;
        }

        const { name, type } = response;
        url = response.url;
        createImageBlockContent({ blockId, url, type, name, organizationId: orgId });
      } else {
        url = blockContent.url;
      }

      updateBlockContent(
        blockId,
        {
          url,
          fileUploadProgress: 100,
          fileUploadStatus: 'complete',
        },
        { undoable: false, syncable: true }
      );
    } else if (blockContent.type === 'pdf') {
      if (!blockContent.file || blockContent.contentUUID) {
        return;
      }
      const file = blockContent.file;

      const result = await runWithRetries(() => extractPdfBlockContentFromFile(file));
      if (!result) {
        updateBlockContent(blockId, {
          fileUploadStatus: 'error',
        });
        return;
      }
      let { thumbnailUrl } = result;
      updateBlockContent(
        blockId,
        {
          thumbnailUrl,
          metadata: blockContent.metadata as PdfMetadata,
        },
        { undoable: false, syncable: true }
      );
      updateBlockContent(blockId, { fileUploadStatus: 'in-progress' });
      let uploadResult = await runWithRetries(() =>
        uploadPdfBlockFile(file, orgId, uploadProgressCallback, assignUploadAborter)
      );

      if (!uploadResult) {
        updateBlockContent(blockId, { fileUploadStatus: 'error' });
        return;
      }
      updateBlockContent(
        blockId,
        {
          fileUploadStatus: 'complete',
          fileUploadProgress: 100,
          contentUUID: uploadResult.jnyContentUUID,
          jnyContent: uploadResult.jnyContent,
        },
        { undoable: false, syncable: true }
      );
    } else if (blockContent.type === 'attachment') {
      if (!blockContent.file || blockContent.contentUUID) {
        return;
      }
      let updatedContent = await extractAttachmentBlockContentFromFile(blockContent);
      if (!updatedContent) {
        return;
      }
      updateBlockContent(blockId, updatedContent, { undoable: false, syncable: true });
      updateBlockContent(blockId, { fileUploadStatus: 'in-progress' });
      const file = blockContent.file;
      updatedContent = await runWithRetries(() =>
        uploadAttachmentBlockFile(file, orgId, uploadProgressCallback, assignUploadAborter)
      );
      removeOngoingUploadAborter(blockId);
      updateBlockContent(
        blockId,
        {
          fileUploadStatus: 'complete',
          fileUploadProgress: 100,
          ...updatedContent,
        },
        { undoable: false, syncable: true }
      );
    } else if (blockContent.type === 'ms-office') {
      if (!blockContent.file) return;

      updateBlockContent(blockId, { fileUploadStatus: 'in-progress' });
      const file = blockContent.file;
      let updatedContent = await runWithRetries(() =>
        uploadAttachmentBlockFile(file, orgId, uploadProgressCallback, assignUploadAborter)
      );
      updateBlockContent(
        blockId,
        {
          fileUploadStatus: 'complete',
          fileUploadProgress: 100,
          ...updatedContent,
        },
        { undoable: false, syncable: true }
      );
    } else if (blockContent.type === 'video') {
      const { file, externalUrl, contentUUID, metadata, importParams } = blockContent;
      // if content uuid exists, it means that the video has already been uploaded and processed
      if (contentUUID) return;

      // if file and url don't exist, then halt
      if (!file && !externalUrl) return;

      try {
        updateBlockContent(blockId, { fileUploadStatus: 'in-progress' });
        let updatedContent: Partial<VideoBlockContent> | undefined;

        if (file) {
          updatedContent = await runWithRetries(() =>
            uploadVideoBlockFile(file, orgId, uploadProgressCallback, assignUploadAborter)
          );
        } else if (externalUrl) {
          updatedContent = await uploadVideoUrl(externalUrl, metadata?.name || '', importParams, orgId);
        }

        if (!updatedContent) {
          return;
        }

        updateBlockContent(
          blockId,
          {
            ...updatedContent,
            fileUploadStatus: 'complete',
            fileUploadProgress: 100,
          },
          { undoable: false, syncable: true }
        );
        let breakLoop = false;
        while (!breakLoop) {
          const block = getBlockIfExists(blockId);
          if (!block) {
            break;
          }
          const blockContent = block.content as VideoBlockContent;
          const newParams = await fetchVideoBlockPlaybackParams(blockContent);
          if (newParams) {
            updateBlockContent(
              blockId,
              {
                muxPlaybackId: newParams.muxPlaybackId,
                posterUrl: newParams.posterUrl,
                metadata: {
                  ...blockContent.metadata,
                  aspectRatio: newParams.aspectRatio,
                  duration: newParams.duration,
                },
                hasTranscript: newParams.hasTranscript || false,
              } as Partial<VideoBlockContent>,
              { undoable: false, syncable: true }
            );
            if (newParams.hasTranscript) {
              breakLoop = true;
              break;
            }
          }
          await new Promise((resolve) => setTimeout(resolve, 2000));
        }
      } catch (e) {
        console.error(e);
      }
    }
  }, 0);
}

export const useEditorStore = create<State>()(
  subscribeWithSelector((set, get) => ({
    ...createInitialState(),
    undo: () => {
      const { undoStack, dispatchUserEditorAction } = get();
      // console.log('UNDO', undoStack);
      if (undoStack.length > 0) {
        const action = undoStack[undoStack.length - 1];
        set({ undoStack: undoStack.slice(0, undoStack.length - 1) });
        dispatchUserEditorAction(action, { source: 'undo' });
      }
    },
    redo: () => {
      const { redoStack, dispatchUserEditorAction } = get();
      // console.log('REDO', redoStack);
      if (redoStack.length > 0) {
        const action = redoStack[redoStack.length - 1];
        set({ redoStack: redoStack.slice(0, redoStack.length - 1) });
        dispatchUserEditorAction(action, { source: 'redo' });
      }
    },
    getSelectedBlock() {
      const {
        selectedBlockId,
        layout: { renderElements },
      } = get();
      if (selectedBlockId) {
        const renderElement = renderElements.find(
          (element) => element.type === 'block' && element.id === selectedBlockId
        );
        if (renderElement) {
          return (renderElement as BlockRenderElement).block;
        }
      }
      return null;
    },
    getSectionById: (sectionId: Section['id']) => {
      const {
        layout: { sections },
      } = get();
      return sections.find((s) => s.id === sectionId) || null;
    },
    getBlockById(blockId: Block['id']) {
      const {
        layout: { sections },
      } = get();
      return findBlockById(sections, blockId);
    },
    setResizeDragState: (resizeDragState: Nullable<ResizeDragState>) => {
      set({ resizeDragState });
    },
    setHoverBlockId: (blockId: Nullable<Block['id']>) => {
      set({ hoverBlockId: blockId });
    },
    setFocusedBlockId: (blockId: Nullable<Block['id']>) => {
      set({ focusedBlockId: blockId });
    },
    selectBlock: (blockId: string, coords?: Coords) => {
      set({ selectedBlockId: blockId, selectionCoords: coords || null });
    },
    clearSelectedBlock: () => {
      set({ selectedBlockId: null });
    },
    setCurrentSectionId: (currentSectionId: string) => {
      set({ currentSectionId });
    },
    setScrollToSectionId: (scrollToSectionId: Nullable<string>) => {
      set({ scrollToSectionId });
    },
    clearScrollToInitialSection: () => {
      set({ initialSectionId: null });
    },
    setScrollToBlockId: (scrollToBlockId: string) => {
      set({ scrollToBlockId });
    },
    setCanvasScrollPosition: (x: number, y: number) => {
      set({ canvasScrollPosition: { x, y } });
    },
    setContainerRects: ({ editorContentRect, outerAreaRect, innerAreaRect }, editorContentElement) => {
      set({ editorContentRect, outerAreaRect, innerAreaRect, editorContentElement });
      const innerAreaWidth = innerAreaRect.width;
      const innerAreaHeight = editorContentRect.height;
      const outerAreaLeftOffset = innerAreaRect.x - outerAreaRect.x;
      const outerAreaRightOffset = outerAreaRect.x + outerAreaRect.width - innerAreaRect.x - innerAreaRect.width;
      lm.setLayoutInputs({
        innerAreaWidth,
        innerAreaHeight,
        outerAreaLeftOffset,
        outerAreaRightOffset,
      });
    },
    setBlockContentSize: (blockId: string, size: Nullable<Size>) => {
      lm.setBlockContentSize(blockId, size);
    },
    setContextMenuState: (contextMenuState: ContextMenuState) => {
      set({ contextMenuState });
    },
    clearContextMenuState: () => {
      set({ contextMenuState: null });
    },
    dispatchUserEditorAction: (userEditorAction: UserEditorAction, config) => {
      const { undoable = true, syncable = true, source = 'none' } = config || {};
      let operations: Operation[] | undefined;

      let inverse = null;

      console.log('dispatchUserEditorAction', userEditorAction, config);
      const { type } = userEditorAction;
      const {
        layout: oldLayout,
        themeSettings: oldColorSettings,
        undoStack,
        redoStack,
        noUndoableActionYet,
        latestTrackableSections,
        initialTitleBlockId,
        journeyName,
        dispatchUserEditorAction,
      } = get();
      if (!latestTrackableSections) {
        console.warn('No jdoc');
        return Promise.reject('No jdoc');
      }
      set({ lastActionWasUndoOrRedo: source === 'undo' || source === 'redo' });

      if (type === 'update-block-content') {
        if (source === 'none') {
          maybeEditTitle(
            initialTitleBlockId,
            oldLayout?.sections,
            userEditorAction?.id,
            userEditorAction?.content as TextBlockContent
          );
        }
      } else if (type === 'delete-block') {
        if (get().selectedBlockId === userEditorAction.id) {
          get().clearSelectedBlock();
        }
      } else if (type === 'delete-section') {
        if (oldLayout.sections.length === 1 && oldLayout.sections[0].id === userEditorAction.id) {
          setTimeout(() => {
            dispatchUserEditorAction({
              type: 'append-section',
              id: generateBlockId(),
            });
          });
        }
      }

      if (type === 'set-journey-name') {
        set({ journeyName: userEditorAction.name });
        inverse = {
          type: 'set-journey-name',
          name: journeyName || '',
        } as SetJourneyNameAction;
      } else if (type === 'set-journey-color-settings') {
        set({ themeSettings: userEditorAction.themeSettings });
        inverse = {
          type: 'set-journey-color-settings',
          themeSettings: oldColorSettings,
        } as SetJourneyColorSettingsAction;
      } else if (type === 'set-talk-to-journey-settings') {
        //
      } else if (isMutualActonPlanAction(userEditorAction)) {
        if (userEditorAction.type === 'update-mutual-action-plan') {
          maybeSetSectionTitleOnMutualActionPlanTitleUpdate(
            userEditorAction.mutualActionPlan.uuid,
            oldLayout.sections,
            userEditorAction.mutualActionPlan.title
          );
        }
        inverse = useMutualActionPlansContext.getState().dispatch(userEditorAction as MutualActionPlanAction);
        console.log('inverse', inverse);
      } else {
        operations = updateLayoutManagerForAction(lm, userEditorAction);
        // console.log('operations', operations);
        if (operations && operations.length > 0) {
          for (const operation of operations) {
            if (operation.type === 'create-block') {
              if (operation.block.content.type === 'mutual-action-plan') {
                setTimeout(() => {
                  // Made async to be similar to 'delete-block' handling below
                  const blockContent = operation.block.content as MutualActionPlanBlockContent;
                  dispatchUserEditorAction(
                    {
                      type: 'create-mutual-action-plan',
                      mutualActionPlan: {
                        uuid: blockContent.uuid,
                        title: blockContent.title,
                        items: blockContent.items,
                      },
                      blockId: operation.block.id,
                    },
                    {
                      syncable: true,
                      undoable: false,
                    }
                  );
                }, 0);
              }
            } else if (operation.type === 'delete-block') {
              setTimeout(() => {
                // We do need to do this async since we do not state before
                // action plan is deleted to infer proper undo action.
                maybeDeleteMutualActionPlan(operation.blockId, oldLayout.sections);
              }, 0);
            } else if (operation.type === 'set-block-content') {
              postSetBlockContentOperationUploadHandler(operation.id, operation.content);
            }
          }

          lm.applyOperations(operations);
        }
      }

      const newLayout = get().layout;

      setTimeout(() => {
        if (operations) maybeSetSectionTitle(operations, oldLayout.sections, newLayout.sections);
      }, 0);

      if (undoable) {
        const oldState = source === 'undo' ? oldLayout.sections : latestTrackableSections;
        if (inverse === null) {
          if (operations && operations.length > 0) {
            inverse = inverseOperations(operations, oldState, lm.sections);
          }
        }

        if (inverse != null) {
          set({ latestTrackableSections: lm.sections });

          if (source === 'none') {
            set({ undoStack: [...undoStack, inverse], redoStack: [] });
          } else if (source === 'undo') {
            set({ redoStack: [...redoStack, inverse] });
          } else if (source === 'redo') {
            set({ undoStack: [...undoStack, inverse] });
          }
        }
        // set({ noUndoableActionYet: false });
      } else {
        // if (noUndoableActionYet) {
        //   set({ latestTrackableSections: lm.sections });
        // }
      }

      if (syncable) {
        if (operations && Array.isArray(operations)) {
          if (operations.length > 0) {
            return sync({ type: 'apply-operations', operations }, oldLayout.sections, lm.sections, source);
          }
        } else {
          return sync(userEditorAction, oldLayout.sections, lm.sections, source);
        }
      }

      return Promise.resolve();
    },
    clearLastActionWasUndoOrRedo: () => {
      set({ lastActionWasUndoOrRedo: false });
    },
    initEditor: async (journeyUUID, journeyName, sections, themeSettings, featureFlags, initialSectionId) => {
      // console.log('initEditor', jDoc);
      lm.initialize(sections);

      await useMutualActionPlansContext.getState().initializeState(sections);

      set({
        latestTrackableSections: sections,
        journeyName,
        journeyUUID,
        themeSettings,
        featureFlags,
        initialized: true,
        currentSectionId: initialSectionId,
        initialSectionId,
      });

      setTimeout(() => {
        checkAndInvokePostInit();
      }, 0);
    },
    resetEditor() {
      set(createInitialState());
      lm.reset();
      postInitWorkQ.killAndDrain();
    },
    setOffline() {
      set({ online: false });
    },
    setOnline() {
      set({ online: true });
    },
    setInitialTitleBlockId(id) {
      set({ initialTitleBlockId: id });
    },
    setReorderBoundary(boundary: Nullable<Boundary>) {
      set({ reorderBoundary: boundary });
    },
    setInsertionBoundary(boundary: Nullable<Boundary>) {
      set({ insertionBoundary: boundary });
    },
    setIsDraggingSomethingOnEditor(isDragging) {
      set({ isDraggingSomethingOnEditor: isDragging });
    },
    setRootRenderMode(mode) {
      set({ rootRenderMode: mode });
    },
    findBlockByContentUuid(uuid) {
      const { layout } = get();
      const blocks = layout.sections.flatMap((s) => s.blocks);
      return blocks.find((b) => (b.content as HasContentUUID)?.contentUUID === uuid) ?? null;
    },
    findSectionById(id) {
      const { layout } = get();
      return layout.sections.find((s) => s.id === id) ?? null;
    },
    triggerBlockResourceLoad(blockId) {
      if (!getFeatureFlag('enable_pdf_lazy_loading')) {
        return;
      }
      const {
        layout: { sections },
      } = get();
      const block = findBlock(sections, blockId);
      if (block) {
        if (block.content.type === 'pdf') {
          const content = block.content as PdfBlockContent;
          if (!arePdfBlockResourcesReady(content)) {
            const existingTask = postInitWorkQ.getQueue().find((task) => task.block.id === blockId);
            if (!existingTask) {
              postInitWorkQ.unshift({ block });
            }
          }
        }
      }
    },
    setSectionDrawerOpen: (open: boolean | ((open: boolean) => boolean)) => {
      if (typeof open === 'function') {
        open = open(get().sectionDrawerOpen);
      }
      console.log('setSectionDrawerOpen', open);
      set({ sectionDrawerOpen: open });
    },
    setSectionHidden: (id, hidden) =>
      set((state) => {
        const index = state.sectionsHidden.findIndex((item) => item.id === id);
        const currentSectionIndex = state.layout.sections.findIndex((section) => section.id === id);

        const hiddenSections = state.layout.sections.map((section, i) =>
          i === currentSectionIndex ? { ...section, hidden } : section
        );

        if (index !== -1) {
          const updatedSections = state.sectionsHidden.map((item, i) => (i === index ? { ...item, hidden } : item));
          return { sectionsHidden: updatedSections, layout: { ...state.layout, sections: hiddenSections } };
        } else {
          return {
            sectionsHidden: [...state.sectionsHidden, { id, hidden }],
            layout: { ...state.layout, sections: hiddenSections },
          };
        }
      }),
  }))
);

function checkAndInvokePostInit() {
  const { initialized, postInitInvoked, layout } = useEditorStore.getState();
  if (initialized && layout.layoutReady && !postInitInvoked) {
    useEditorStore.setState({ postInitInvoked: true });
    onInitEditor();
  }
}

type PostInitTask = {
  block: Block;
};

const postInitWorkQ: queueAsPromised<PostInitTask> = fastq.promise(async ({ block }) => {
  const updateBlockContent = (
    blockId: string,
    content: Partial<BlockContent>,
    config: { undoable: boolean; syncable: boolean } = { undoable: false, syncable: false }
  ) => {
    useEditorStore.getState().dispatchUserEditorAction(
      {
        type: 'update-block-content',
        id: blockId,
        content,
      },
      config
    );
  };

  const getBlockIfExists = (blockId: string) =>
    useEditorStore
      .getState()
      .layout.sections.flatMap((section) => section.blocks)
      .find((block) => block.id === blockId);

  if (block.content.type === 'pdf') {
    const content = block.content as PdfBlockContent;
    updateBlockContent(block.id, {
      fileStatus: 'in-progress',
    } as Partial<PdfBlockContent>);
    let updatedContent = await fetchPdfBlockJnyContent(content, (contentName, meta) => {
      EditorContentNamePdfMetaMapping.set(contentName, meta);
    });
    if (!updatedContent) {
      return;
    }
    updateBlockContent(block.id, {
      ...updatedContent,
      fileStatus: 'none',
    } as Partial<PdfBlockContent>);
  } else if (block.content.type === 'video') {
    let breakLoop = false;
    while (!breakLoop) {
      const updatedBlock = getBlockIfExists(block.id);
      if (!updatedBlock) {
        break;
      }
      const updatedContent = updatedBlock.content as VideoBlockContent;
      const newParams = await fetchVideoBlockPlaybackParams(updatedContent);
      if (newParams) {
        const { muxPlaybackId, hasTranscript } = newParams;

        updateBlockContent(
          block.id,
          {
            muxPlaybackId: newParams.muxPlaybackId,
            posterUrl: newParams.posterUrl,
            metadata: {
              ...updatedContent.metadata,
              aspectRatio: newParams.aspectRatio,
              duration: newParams.duration,
            },
            fileStatus: 'none',
            hasTranscript: newParams.hasTranscript || false,
          } as Partial<VideoBlockContent>,
          {
            undoable: false,
            syncable: true,
          }
        );
        if (hasTranscript) {
          breakLoop = true;
        }
        break;
      }
      await new Promise((resolve) => setTimeout(resolve, 2000));
    }
  }
  await new Promise((resolve) => setTimeout(resolve, 500));
}, 1);

async function onInitEditor() {
  const { layout } = useEditorStore.getState();
  const blocks = layout.sections.flatMap((s) => s.blocks);

  const replaceBlock = (blockId: string, content: BlockContent) => {
    useEditorStore.getState().dispatchUserEditorAction(
      {
        type: 'replace-block',
        id: blockId,
        content,
        layoutExtraParams: {},
      },
      { undoable: false, syncable: true }
    );
  };

  blocks.forEach(async (block) => {
    if (block.content.type === 'pdf') {
      const content = block.content as PdfBlockContent;
      if (!content.contentUUID) {
        replaceBlock(block.id, {
          type: 'placeholder',
          placeholderType: 'add-file',
        });
        return;
      }
      if (!getFeatureFlag('enable_pdf_lazy_loading')) {
        postInitWorkQ.push({ block });
      }
    } else if (block.content.type === 'video') {
      const content = block.content as VideoBlockContent;
      if (!content.contentUUID) {
        replaceBlock(block.id, {
          type: 'placeholder',
          placeholderType: 'add-file',
        });
        return;
      }
      if (!content.muxPlaybackId || !content.posterUrl || !content.hasTranscript) {
        postInitWorkQ.push({ block });
      }
    } else if (block.content.type === 'attachment') {
      if (!block.content.contentUUID) {
        replaceBlock(block.id, {
          type: 'placeholder',
          placeholderType: 'add-file',
        });
        return;
      }
    } else if (block.content.type === 'image') {
      if (!block.content.url) {
        replaceBlock(block.id, {
          type: 'placeholder',
          placeholderType: 'add-image',
        });
        return;
      }
    }
  });
}

function maybeEditTitle(
  initialTitleBlockId: Nullable<Block['id']>,
  sections: Section[],
  editedBlockId: Block['id'],
  content: TextBlockContent
) {
  if (initialTitleBlockId === editedBlockId && sections.length === 1 && sections[0].blocks.length === 1) {
    const headingText = content?.valueJSON?.content[0]?.content[0]?.text?.slice(0, 80);
    if (headingText !== null) {
      setTimeout(() => {
        useEditorStore.getState().dispatchUserEditorAction(
          {
            type: 'set-journey-name',
            name: headingText,
          },
          {
            syncable: true,
            undoable: false,
          }
        );
      }, 0);
    }
  }
}

function maybeDeleteMutualActionPlan(id: Block['id'], sections: Section[]) {
  const block = findBlockById(sections, id)!;
  if (block.content.type === 'mutual-action-plan') {
    useEditorStore.getState().dispatchUserEditorAction(
      {
        type: 'delete-mutual-action-plan',
        mutualActionPlan: { uuid: block.content.uuid },
        blockId: id,
      },
      {
        syncable: true,
        undoable: false,
      }
    );
  }
}

// @ts-ignore
window.editorStore = useEditorStore;

// @ts-ignore
window.lm = lm;
