import fastq from 'fastq';

import { apiNeueDispatchAction } from 'src/utils/journeyApi';
import { useEditorContext } from 'src/store/editor';
import { useCurrentOrganization } from 'src/store/organization';
import { useEditorStore } from './editor-store';
import { ActionSource, Operation, Section, UserEditorAction } from './types';
import { blockToServerBlock } from './helpers/transforms/block-to-server-block';
import { findSectionByBlockId } from './helpers/section/find-section-by-block-id';
import { findSectionById } from './helpers/section/find-section-by-id';
import { mapMutualActionPlanActionToServerAction } from './mutual-action-plans/server-action';
import { isMutualActonPlanAction, MutualActionPlanAction } from './mutual-action-plans/types';
import { findBlockById } from './helpers/block/find-block-by-id';

const BACKOFF_TIMEOUT_MS = 4000;
const MIN_TIMEOUT_MS = 50;
const BACKOFF_ITERATION = Math.floor(Math.log2(BACKOFF_TIMEOUT_MS / MIN_TIMEOUT_MS));

function timeout(iteration: number): number {
  if (iteration <= BACKOFF_ITERATION) {
    return MIN_TIMEOUT_MS * Math.pow(2, iteration);
  } else {
    return BACKOFF_TIMEOUT_MS;
  }
}
async function sendWithRetries(f: Function) {
  for (let i = 0; ; i++) {
    try {
      const result = await f();
      useEditorStore.getState().setOnline();
      return result;
    } catch (err: any) {
      if (err.message === 'Network Error') {
        console.log(err);
        useEditorStore.getState().setOffline();
        await new Promise((r) => setTimeout(r, timeout(i)));
      } else {
        return Promise.reject(err);
      }
    }
  }
}

const q = fastq.promise(async ({ context, actions }: { context: any; actions: any[] }) => {
  const result = await sendWithRetries(() => apiNeueDispatchAction(context, actions));
  return result;
}, 1);

export async function enqueNeueDispatchActions(context: any, actions: any[]): Promise<any> {
  return await q.push({ context, actions });
}

function serverOperations(ops: Operation[], oldState: Section[], newState: Section[], source: ActionSource): any[] {
  return ops.flatMap((op) => {
    const { type: operationType } = op;
    if (operationType === 'create-block') {
      const block = findBlockById(newState, op.block.id);
      return [
        {
          type: 'set-block',
          payload: {
            block_uuid: op.block.id,
            section_uuid: op.sectionId,
            block: blockToServerBlock(block!),
          },
        } as any,
      ];
    } else if (operationType === 'delete-block') {
      const section = findSectionById(newState, op.sectionId);

      if (!section) {
        return [];
      }

      return [
        {
          type: 'delete-block',
          payload: {
            block_uuid: op.blockId,
          },
        },
      ];
    } else if (operationType === 'move-block') {
      const oldFromSection = findSectionByBlockId(oldState, op.id)!;
      const newFromSection = findSectionById(newState, oldFromSection.id)!;

      if (!newFromSection) {
        return [];
      }

      return [
        {
          type: 'move-block',
          payload: {
            block_uuid: op.id,
            new_section_uuid: op.to.sectionId,
            new_position: op.to.newPosition,
          },
        },
      ];
    } else if (operationType === 'set-block-width') {
      const section = findSectionByBlockId(newState, op.id)!;
      const block = findBlockById(newState, op.id)!;
      return [
        {
          type: 'set-block',
          payload: {
            block_uuid: op.id,
            section_uuid: section.id,
            block: blockToServerBlock(block),
          },
        },
      ];
    } else if (operationType === 'set-block-content') {
      const section = findSectionByBlockId(newState, op.id)!;
      const block = findBlockById(newState, op.id)!;
      return [
        {
          type: 'set-block',
          payload: {
            block_uuid: op.id,
            section_uuid: section.id,
            block: blockToServerBlock(block),
          },
        },
      ];
    } else if (operationType === 'update-block-content') {
      const section = findSectionByBlockId(newState, op.id)!;
      const block = findBlockById(newState, op.id)!;
      return [
        {
          type: 'set-block',
          payload: {
            block_uuid: op.id,
            section_uuid: section.id,
            block: blockToServerBlock(block),
          },
        },
      ];
    } else if (operationType === 'set-block-layout') {
      const section = findSectionByBlockId(newState, op.id)!;
      const block = findBlockById(newState, op.id)!;
      return [
        {
          type: 'set-block',
          payload: {
            block_uuid: op.id,
            section_uuid: section.id,
            block: blockToServerBlock(block),
          },
        },
      ];
    } else if (operationType === 'delete-section') {
      return [
        {
          type: 'delete-step',
          payload: {
            step_uuid: op.id,
          },
        },
      ];
    } else if (operationType === 'hidden-section') {
      const sections = useEditorStore.getState().layout.sections
      const currentSection = findSectionById(sections, op.id)!;
      
      return [
        {
          type: 'hidden-step',
          payload: {
            step_uuid: op.id,
            hidden: currentSection.hidden
          },
        },
      ];
    } else if (operationType === 'folder-update') {
      return [
        {
          type: 'folder-update',
          payload: {
            step_uuid: op.id,
            folder_key: op.folder_key,
          },
        },
      ];
    } else if (operationType === 'folder-set-name') {
      return [
        {
          type: 'folder-set-name',
          payload: {
            step_uuid: op.id,
            folder_name: op.folder_name,
          },
        },
      ];
    } else if (operationType === 'create-section') {
      let previousSectionId = null;
      if (op.index > 0) {
        previousSectionId = newState[op.index - 1].id;
      }
      return [
        {
          type: 'create-step',
          payload: {
            step_uuid: op.id,
            step_name: op.name || null,
            previous_step_uuid: previousSectionId,
          },
        },
      ];
    } else if (operationType === 'move-section') {
      let newPreviousSectionId = null;
      if (op.to.index > 0) {
        newPreviousSectionId = newState[op.to.index - 1].id;
      }
      return [
        {
          type: 'move-step',
          payload: {
            step_uuid: op.id,
            new_previous_step_uuid: newPreviousSectionId,
          },
        },
      ];
    } else if (operationType === 'set-section-name') {
      return [
        {
          type: 'set-step-name',
          payload: {
            step_uuid: op.id,
            name: op.name,
            set_manually: op.setManually,
          },
        },
      ];
    } else if (operationType === 'set-block-content-uuid') {
      return [
        {
          type: 'set-block-content-uuid',
          payload: {
            block_uuid: op.id,
            content_uuid: op.contentUUID,
          },
        },
      ];
    } else {
      throw new Error('Unknown operation type: ' + operationType);
    }
  });
}

export function mapToServerAction(
  action: UserEditorAction,
  oldState: Section[],
  newState: Section[],
  source: ActionSource
): any[] {
  if (isMutualActonPlanAction(action)) {
    return mapMutualActionPlanActionToServerAction(action as MutualActionPlanAction, oldState, newState, source);
  }

  console.log('updated action type', action.type);

  switch (action.type) {
    case 'set-image-url-after-upload': {
      const section = findSectionByBlockId(newState, action.id);
      const block = findBlockById(newState, action.id)!;
      if (!section) {
        return [];
      }
      return [
        {
          type: 'set-block',
          payload: {
            section_uuid: section.id,
            block_uuid: action.id,
            block: blockToServerBlock(block),
          },
        },
      ];
    }
    case 'set-journey-name': {
      return [
        {
          type: 'set-journey-name',
          payload: {
            name: action.name,
          },
        },
      ];
    }
    case 'set-talk-to-journey-settings': {
      return [
        {
          type: 'set-talk-to-journey-settings',
          payload: {
            settings: action.settings,
          },
        },
      ];
    }
    case 'apply-operations': {
      return serverOperations(action.operations, oldState, newState, source);
    }
    case 'set-journey-color-settings': {
      let customThemeValues = null;
      if (action.themeSettings.theme === 'custom') {
        customThemeValues = action.themeSettings.colorValues;
      }
      return [
        {
          type: 'set-journey-color-settings',
          payload: {
            theme: action.themeSettings.theme,
            brand_color: action.themeSettings.brandColor,
            font_preferences: {
              headings: {
                font_family: action.themeSettings.fontValues.headings.fontFamily,
                font_weight: action.themeSettings.fontValues.headings.fontWeight,
              },
              paragraphs: {
                font_family: action.themeSettings.fontValues.paragraphs.fontFamily,
                font_weight: action.themeSettings.fontValues.paragraphs.fontWeight,
              },
              size_multiplier: action.themeSettings.fontValues.size_multiplier.toString(),
            },
            ...(customThemeValues && { custom_theme_values: customThemeValues }),
          },
        },
      ];
    }
    default: {
      const exhaustiveCheck: any = action;
      throw new Error(`Unhandled case: ${JSON.stringify(exhaustiveCheck)}`);
    }
  }
}

export async function sync(action: UserEditorAction, oldState: Section[], newState: Section[], source: ActionSource) {
  const serverActions = mapToServerAction(action, oldState, newState, source);
  console.log('sendActionToServer', serverActions);
  const { journey } = useEditorContext.getState();
  const { currentOrganization } = useCurrentOrganization.getState();
  const context = {
    journey_uuid: journey.uuid,
    organization_id: currentOrganization.id,
  };
  try {
    const response = await enqueNeueDispatchActions(context, serverActions);
    const { journey } = response;
    useEditorContext.getState().setJourney(journey);
  } catch (e) {
    console.error('Error dispatching action to server', e);
    return Promise.reject(`Error dispatching action to server: ${e}`);
  }
}