import React from 'react';
import { attachLinkTracker, SegmentEventName, SegmentEventProperties } from './segment';

export function useSegmentTrackedLink<K extends SegmentEventName>(
  event_name: K,
  properties: SegmentEventProperties<K>
) {
  const linkRef = React.useRef<HTMLAnchorElement>(null);

  React.useEffect(() => {
    if (linkRef.current) {
      attachLinkTracker(linkRef.current, event_name, properties);
    }
  }, [linkRef.current]);

  return linkRef;
}
