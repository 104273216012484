import React, { useRef } from 'react';
import classNames from 'classnames';
import { Nullable } from 'src/types/nullable.type';
import { useNeueLayoutMode } from '../helpers/neue-layout-mode.hook';
import { usePlayerStore } from './player-store';
import { promiseDelay } from 'src/common/helpers/promise-delay';
import { getHtmlText } from '../helpers/get-html-text';
import { useMutualActionPlansContext } from '../mutual-action-plans/store';
import { getJourneyThemeSettings } from '../helpers/get-journey-theme-settings';
import { NeueLoadingSpinner } from '../components/loading/spinner';
import { NeuePlayerContent } from './neue-player-content';
import { MemoizedNeuePlayerBlocksViewContainer } from './blocks-view-container';
import { Journey } from 'src/common/interfaces/journey.interface';
import { journeyToSections } from '../helpers/transforms/journey-to-sections';
import { createPropsEqualsChecker } from '../utils/create-props-equals-checker';
import { useScrollToSectionById } from './scroll-to-section-by-id.hook';
import { NeuePlayerTopBar } from './top-bar';
import { functionNoop } from 'src/utils/function/noop';
import { SignUpSectionSchema } from './sign-up/section-schema';
import { useRefCallback } from 'src/utils/react/ref-callback.hook';
import { apiGetPublicApiKeys } from 'src/utils/journeyApi';
import injectHubspotScript from 'src/utils/integration/hubspot';
import { useEditorStore } from '../editor-store';
import { useApplyHighlightTheme } from '../utils/apply-highlight-theme.hook';
import { shallow } from 'zustand/shallow';

type Props = {
  journey: Nullable<Journey>;
  stepPath?: Nullable<string>;
  artificialDelay?: number;
  pdfPreview?: boolean;
  previewMode?: boolean;
};

export const NeuePlayerJourneyView = ({
  journey,
  stepPath,
  artificialDelay = 0,
  previewMode = false,
  pdfPreview,
}: Props) => {
  const initPlayer = usePlayerStore((state) => state.initPlayer);
  const contentContainerElementRef = useRef<Nullable<HTMLDivElement>>(null);
  const initializeMutualActionPlanState = useMutualActionPlansContext((state) => state.initializeState);
  const getMutualActionPlan = useMutualActionPlansContext((state) => state.getMutualActionPlan);
  const resetPlayer = usePlayerStore((state) => state.resetPlayer);
  const setLayoutMode = usePlayerStore((state) => state.setLayoutMode);
  const findSectionByFriendlyPath = usePlayerStore((state) => state.findSectionByFriendlyPath);
  const layoutStage = usePlayerStore((state) => state.layoutStage);
  const [loadingJourney, setLoadingJourney] = React.useState(true);
  const { layoutMode } = useNeueLayoutMode();
  const layoutReady = usePlayerStore((state) => state.layout.layoutReady);
  const currentSectionIndex = usePlayerStore((state) => state.currentSectionIndex);
  const sections = usePlayerStore((state) => state.layout.sections);
  const setCurrentSectionIndex = usePlayerStore((state) => state.setCurrentSectionIndex);
  const toggleMapCallbackRef = useRef(functionNoop);
  const rootRenderMode = useEditorStore((state) => state.rootRenderMode);

  const themeSettings = usePlayerStore((state) => state.themeSettings, shallow);
  useApplyHighlightTheme({ themeSettings, containerElement: document, isLayoutReady: layoutReady });

  const scrollToSectionById = useScrollToSectionById(functionNoop);

  React.useLayoutEffect(() => {
    setLayoutMode(layoutMode);
  }, [layoutMode]);

  const isPlayerReady = !loadingJourney && !!journey && layoutReady && layoutStage === 'ready';

  const fetchIntegrations = async () => {
    if (!journey) return;
    // if in player preview mode, then don't fetch integrations
    if (rootRenderMode && rootRenderMode === 'player') return;

    // if in pdf generation mode, then don't fetch integrations
    if (pdfPreview) return;

    try {
      const { api_keys } = await apiGetPublicApiKeys(journey.organization!.slug, 'hubspot');
      if (api_keys && api_keys.length) {
        const first_api_key = api_keys[0];
        if (first_api_key && first_api_key.api_key) {
          await injectHubspotScript(api_keys[0].api_key);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  React.useEffect(() => {
    if (!journey) return;
    const initializeJourney = async () => {
      let sections = journeyToSections(journey, true);

      await initializeMutualActionPlanState(sections);

      // Filter blocks that are practically empty
      sections = sections
        .map((section) => {
          return {
            ...section,
            blocks: section.blocks.filter((block) => {
              const content = block.content;
              const type = content.type;
              if (section.hidden) {
                return false;
              }
              if (type === 'text') {
                return getHtmlText(content.value).length > 0;
              } else if (type === 'image') {
                return !!content.url;
              } else if (type === 'placeholder') {
                return false;
              } else if (type === 'logo') {
                if (!content.recipientLogoEnabled && !content.orgLogoEnabled) {
                  return false;
                }
                if (!content.selectedOrgLogo && !content.selectedRecipientLogo) {
                  return false;
                }
                return true;
              } else if (type === 'mutual-action-plan') {
                const plan = getMutualActionPlan(content.uuid);
                return !!plan.title || plan.items.length > 0;
              } else if (type === 'native-e-signature' && pdfPreview) {
                return false;
              }
              return true;
            }),
          };
        })
        .filter((section) => section.blocks.length > 0);

      const brandColor = journey?.branding?.secondary_color || null;
      const themeSettings = getJourneyThemeSettings(journey.theme || null, brandColor);
      const featureFlags = journey.feature_flags || {};

      if (!journey.branding?.hide_signup_block) {
        sections.push(SignUpSectionSchema);
      }
      initPlayer(journey.uuid, sections, themeSettings, featureFlags);
      fetchIntegrations();
    };

    Promise.all([initializeJourney(), promiseDelay(artificialDelay)]).then((_) => {
      setLoadingJourney(false);
    });
    return () => {
      if (journey) {
        resetPlayer();
      }
    };
  }, [journey]);

  React.useEffect(() => {
    if (stepPath && isPlayerReady) {
      const initialSection = findSectionByFriendlyPath(stepPath);

      if (initialSection) {
        scrollToSectionById(initialSection.id);
      }
    }
  }, [stepPath, isPlayerReady]);

  const loading = !isPlayerReady;

  const goToNextSection = useRefCallback(() => {
    if (currentSectionIndex < sections.length - 1) {
      setCurrentSectionIndex(currentSectionIndex + 1);
    }
  }, [currentSectionIndex, sections]);

  const goToPreviousSection = useRefCallback(() => {
    if (currentSectionIndex > 0) {
      setCurrentSectionIndex(currentSectionIndex - 1);
    }
  }, [currentSectionIndex]);

  React.useEffect(() => {
    const listener = function (event: KeyboardEvent) {
      console.log('keydown', event);
      if (event.defaultPrevented) {
        return;
      }
      if (event.target instanceof HTMLInputElement || event.target instanceof HTMLTextAreaElement) {
        return;
      }
      if (event.key === 'ArrowLeft') {
        goToPreviousSection();
      } else if (event.key === 'ArrowRight') {
        goToNextSection();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [goToNextSection, goToPreviousSection]);

  return (
    <div className='w-full h-full relative'>
      <div className={classNames('w-full h-full flex', { 'opacity-0': loading })}>
        {!pdfPreview && (
          <NeuePlayerTopBar
            contentContainerElementRef={contentContainerElementRef}
            onToggleMapRef={toggleMapCallbackRef}
            isMobile={layoutMode !== 'web'}
            layoutMode={layoutMode}
            previewMode={previewMode}
            journey={journey}
          />
        )}
        {/* <NeuePlayerContentNew loading={loading}>
          <MemoizedNeuePlayerBlocksViewContainerNew />
        </NeuePlayerContentNew> */}
        <NeuePlayerContent
          containerElementRef={contentContainerElementRef}
          onToggleMapRef={toggleMapCallbackRef}
          loading={loading}
          hideNavigationBar={pdfPreview}
        >
          <MemoizedNeuePlayerBlocksViewContainer />
        </NeuePlayerContent>
      </div>
      <div
        className={classNames('absolute inset-0 flex items-center justify-center', {
          'opacity-100': loading,
          'opacity-0 pointer-events-none': !loading,
        })}
      >
        <NeueLoadingSpinner size='32' theme={isPlayerReady ? 'canvas' : 'default'} />
      </div>
    </div>
  );
};

export const MemoizedNeuePlayerJourneyView = React.memo(
  NeuePlayerJourneyView,
  createPropsEqualsChecker([], 'NeuePlayerJourneyView')
);
