import dayjs from 'dayjs';
import pluginRelativeTime from 'dayjs/plugin/relativeTime';
import pluginUtc from 'dayjs/plugin/utc';
import pluginTimezone from 'dayjs/plugin/timezone';
import pluginDuration from 'dayjs/plugin/duration';
import pluginAdvancedFormat from 'dayjs/plugin/advancedFormat';

dayjs.extend(pluginRelativeTime);
dayjs.extend(pluginUtc);
dayjs.extend(pluginTimezone);
dayjs.extend(pluginDuration);
dayjs.extend(pluginAdvancedFormat);

dayjs.tz.setDefault(dayjs.tz.guess());

const DEFAULT_DATE_FORMAT = 'D MMM YY';
const DEFAULT_DATE_TIME_FORMAT = 'D MMM YY, h:mmA';
const DURATION_FORMAT_STRINGS = {
  long: {
    seconds: 'ss',
    minutes: 'mm:ss',
    hours: 'HH:mm:ss',
  },
  short: {
    seconds: 's',
    minutes: 'm:ss',
    hours: 'H:mm:ss',
  },
  minimal: {
    seconds: 's[s]',
    minutes: 'm[m] s[s]',
    hours: 'H[h] m[m] s[s]',
  },
};

function fromNowInUtc(utcDatetime) {
  try {
    return dayjs.utc(utcDatetime).fromNow();
  } catch (err) {
    return '';
  }
}

function formatDuration(seconds, { format = 'long', returnEmptyIfZero = true, showAtleastMinutes = true } = {}) {
  const duration = dayjs.duration(seconds, 'seconds');

  if (!seconds) {
    return returnEmptyIfZero ? '' : duration.format(DURATION_FORMAT_STRINGS[format].minutes);
  }

  if (seconds < 60 && !showAtleastMinutes) {
    return duration.format(DURATION_FORMAT_STRINGS[format].seconds);
  }

  if (seconds < 3600) {
    return duration.format(DURATION_FORMAT_STRINGS[format].minutes);
  } else {
    return duration.format(DURATION_FORMAT_STRINGS[format].hours);
  }
}

function formatUnixTimestampSecondsToDate(t) {
  return dayjs.unix(t).format('MMMM D, YYYY');
}

function formatDateTime(datetime, format = DEFAULT_DATE_FORMAT) {
  return dayjs(datetime).format(format);
}

function formatUtcDateTimeToLocal(utcDatetime, format = DEFAULT_DATE_TIME_FORMAT) {
  return dayjs.utc(utcDatetime).local().format(format);
}

function daysSince(datetime) {
  return dayjs.utc().diff(dayjs.utc(datetime), 'day', true);
}

function getDate30DaysAgo(datetime) {
  return dayjs(datetime).subtract(30, 'day');
}

export {
  fromNowInUtc,
  formatDuration,
  formatDateTime,
  formatUtcDateTimeToLocal,
  formatUnixTimestampSecondsToDate,
  daysSince,
  getDate30DaysAgo,
};
