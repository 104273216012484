import objectPath from 'object-path';
import { v4 as uuidv4 } from 'uuid';

import { ErrorInterface } from 'src/common/interfaces/error.interface';
import { Journey } from 'src/common/interfaces/journey.interface';
import { JourneyAlias, AliasCustomFieldType } from 'src/common/interfaces/journey/alias.interface';
import { Nullable } from 'src/types/nullable.type';
import { Organization } from 'src/common/interfaces/organization.interface';
import { boolean } from 'yup';

export type JourneyAliasFormType = Omit<JourneyAlias, 'id' | 'has_password' | 'enabled'>;

export interface ShareLinkFormProps {
  onFormSubmit: (alias: JourneyAlias, thumbnailUrl: Nullable<string>) => void;
  onFormFail: (err: ErrorInterface) => void;
  uuid: string;
  journey: Journey;
  form: JourneyAliasFormType;
}

export interface ShareLinkFormType {
  name: string;
  slug: string;
  blacklisted_emails: string[];
  allowed_emails: string[];
  allow_comments: boolean;
  email_required: boolean;
  email_verification: boolean;
  nda_required: boolean;
  nda_legal_entity_name: string;
  disable_indexing: boolean;
  expires_at: string;
  intro_video_asset: any;
  password: string;
  custom_fields: AliasCustomFieldType[];
  uuid: string;
  thumbnail_url: string;
  personalization_domain: string;
  personalization_logo_url: string;
}

export type ShareLinkFieldType<T> = { field: string; value: T };

export const ShareLinkFormFactory = (
  organization: Organization,
  journey: Nullable<Journey>,
  alias?: Nullable<JourneyAlias>
): JourneyAliasFormType => {
  const journeyThumbnailURL = journey ? objectPath.get(journey, 'thumbnail_url', '') : null;

  // these are global journey settings at organization level that should be used as a default
  const journey_email_required = objectPath.get(organization, 'settings.journey_email_required', false);
  const journey_email_verification_required = objectPath.get(
    organization,
    'settings.journey_email_verification_required',
    false
  );

  if (!alias)
    return {
      name: '',
      slug: uuidv4().replace(/-/g, '').substring(0, 6),
      is_primary: false,
      blacklisted_emails: [],
      allow_comments: true,
      disable_indexing: false,
      allowed_emails: [],
      is_subscribed_to_comments_status: 'enabled',
      email_required: journey_email_required || false,
      email_verification: journey_email_verification_required || false,
      nda_required: false,
      nda_legal_entity_name: organization.name,
      journey_id: '',
      expires_at: '',
      intro_video_asset: null,
      password: '',
      custom_fields: [],
      uuid: '',
      thumbnail_url: journeyThumbnailURL || null,
      personalization_logo_url: '',
      personalization_domain: '',
      personalization: null,
      contact_email: null,
      company_name: null,
      contact_first_name: null,
      contact_last_name: null,
      hubspot_sync: false,
    };

  return {
    name: objectPath.get(alias, 'name', ''),
    allow_comments: objectPath.get(alias, 'allow_comments', true),
    slug: objectPath.get(alias, 'slug', 'custom-link-url'),
    is_primary: objectPath.get(alias, 'is_primary', false),
    blacklisted_emails: objectPath.get(alias, 'blacklisted_emails', []),
    disable_indexing: objectPath.get(alias, 'disable_indexing', false),
    allowed_emails: objectPath.get(alias, 'allowed_emails', []),
    journey_id: objectPath.get(alias, 'journey_id', ''),
    email_required: objectPath.get(alias, 'email_required', false),
    email_verification: objectPath.get(alias, 'email_verification', false),
    nda_required: objectPath.get(alias, 'nda_required', false),
    nda_legal_entity_name: objectPath.get(alias, 'nda_legal_entity_name', '') || organization.name,
    is_subscribed_to_comments_status: objectPath.get(alias, 'is_subscribed_to_comments_status', 'enabled'),
    expires_at: objectPath.get(alias, 'expires_at', '') || '',
    intro_video_asset: objectPath.get(alias, 'intro_video_asset', null),
    password: objectPath.get(alias, 'password', ''),
    custom_fields: objectPath.get(alias, 'custom_fields', []) || [],
    uuid: objectPath.get(alias, 'uuid', ''),
    thumbnail_url: journeyThumbnailURL || null,
    personalization_logo_url: objectPath.get(alias, 'personalization.logo_url', ''),
    personalization_domain: objectPath.get(alias, 'personalization.domain', ''),
    personalization: objectPath.get(alias, 'personalization', null),
    contact_email: objectPath.get(alias, 'contact_email', null),
    company_name: objectPath.get(alias, 'company_name', null),
    contact_first_name: objectPath.get(alias, 'contact_first_name', null),
    contact_last_name: objectPath.get(alias, 'contact_last_name', null),
    hubspot_sync: objectPath.get(alias, 'hubspot_sync', false),
  };
};
